import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getRoom, makeReservation } from "../services/roomService";
import { useFormik } from "formik";
import TextInput from "../components/TextInput";
import { editReservation, getRoomPosition } from "../utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { Modal, ModalContent, Spinner } from "@nextui-org/react";
import toast from "react-hot-toast";
import FloorPlan from "../assets/images/floorplan.jpeg";
import { motion } from "framer-motion";

export default function RoomDetails() {
  const { roomId } = useParams();
  const { state } = useLocation();

  const { data: room, isLoading } = useQuery({
    queryKey: ["room", roomId],
    initialData: state ?? undefined,
    queryFn: () => getRoom(roomId),
  });

  const { mutateAsync, isPending: isBooking } = useMutation({
    mutationFn: (data) => makeReservation(room.id, data),
  });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: parseFloat(room?.price),
      reservations: [{ name: "", email: "" }],
      redirect_url: window?.location.href,
    },
    onSubmit: async (values) => {
      if (values.reservations[0].name) {
        try {
          const paymentUrl = await mutateAsync(values);
          window.location = paymentUrl;
        } catch (err) {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    },
  });

  const addReservation = () => {
    form.setFieldValue("reservations", [
      ...form.values.reservations,
      { name: "", email: "" },
    ]);
  };

  const removeReservation = (position) => {
    form.setFieldValue(
      "reservations",
      form.values.reservations.filter((_, i) => i !== position)
    );
  };

  const beds = !room?.is_available
    ? "Fully booked"
    : room?.beds !== room?.beds_available
    ? `${room?.beds_available} of ${room?.beds} rooms left`
    : `${room?.beds} beds`;

  const [currentImage, setCurrentImage] = useState(room?.images[0]);

  useEffect(() => {
    setCurrentImage(room?.images[0]);
  }, [room?.images]);

  const [searchParams] = useSearchParams();

  const [successModelOpen, setSuccessModalOpen] = useState(
    Boolean(searchParams.get("trxref"))
  );

  return (
    <>
      <Modal
        isOpen={successModelOpen}
        onClose={() => setSuccessModalOpen(false)}
        isDismissable
        backdrop="blur"
        hideCloseButton
      >
        <ModalContent className="rounded-none w-fit">
          {(onClose) => (
            <div className="bg-white w-[23rem] p-10 shadow-lg flex flex-col items-center">
              <div className="bg-primary text-white rounded-full w-fit p-5 mb-6">
                <IconCheck size={50} stroke={2.5} />
              </div>
              <p className="leading-relaxed">
                We are pleased to inform you that your room reservation at Sama
                Hostels was successful. Please check your email for details
                about your reservation.
              </p>
            </div>
          )}
        </ModalContent>
      </Modal>
      <div className="px-[6%] md:px-[8%] py-12 md:py-20 bg-hero-background">
        {isLoading ? (
          <div className="flex justify-center items-center h-[30rem]">
            <Spinner size="lg" />
          </div>
        ) : (
          <div className="md:flex justify-between md:space-x-12">
            <div className="md:w-[60%] shrink-0">
              <div>
                <img
                  src={currentImage?.url}
                  alt="Bedroom"
                  className="h-[16.2rem] md:h-[26.2rem] w-full object-cover"
                />
                {room.images.length > 1 && (
                  <div className="flex mt-4 space-x-4 overflow-x-scroll">
                    {room.images.map((image) => (
                      <img
                        key={image.url}
                        src={image.url}
                        alt="Bedroom"
                        className={`h-[4rem] md:h-[7rem] w-[5rem] md:w-[8.8rem] object-cover cursor-pointer ${
                          image.url === currentImage?.url &&
                          "border-2 border-primary"
                        }`}
                        onClick={() => setCurrentImage(image)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="py-7 px-9 md:flex justify-between w-full bg-white shadow mt-9">
                <div className="flex flex-col">
                  <p className="mb-4 text-xl font-medium">Room {room.number}</p>
                  <p className="text-sm text-primary mb-5">{room.location}</p>
                  <div className="flex justify-between space-x-1.5 md:space-x-5 w-fit mt-auto text-sm font-light">
                    <p className="shrink-0">{beds}</p>
                    <div className="bg-gray-300 h-[1rem] w-[0.07rem]" />
                    <p className="shrink-0">{room.bathrooms} Bathrooms</p>
                    {room.study_desk && (
                      <>
                        <div className="bg-gray-300 h-[1rem] w-[0.07rem]" />
                        <p className="shrink-0">Study Desk</p>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="relative mt-9 bg-white py-7 px-8 shadow">
                <p className="font-medium mb-5">Floor Plans</p>

                <div className="relative h-full">
                  <img src={FloorPlan} alt="Floor Plan" className="h-full" />
                  <motion.div
                    initial={{ width: "100%", height: "100%", top: 0 }}
                    whileInView={{
                      width: "11.8%",
                      height: "8%",
                      ...getRoomPosition(room.number),
                    }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.25, ease: "easeOut" }}
                    className="border-[2px] cursor-pointer hover:bg-primary border-primary absolute shadow bg-black/50 text-white font-medium text-[0.7rem] flex items-center justify-center"
                  >
                    Room {room.number}
                  </motion.div>
                </div>
              </div>
            </div>
            <div className="w-full max-md:mt-10">
              <div className="bg-white w-full py-7 px-8 md:py-8 md:px-10 shadow">
                <p className="font-semibold text-primary text-2xl">
                  GHS{room.price}
                </p>

                <div className="mt-7">
                  <TextInput
                    placeholder="Name"
                    autoFocus
                    value={form.values.reservations[0]?.name}
                    containerClassName="mb-5"
                    onChange={(e) =>
                      form.setFieldValue(
                        "reservations",
                        editReservation(form.values.reservations, 0, {
                          name: e.target.value,
                        })
                      )
                    }
                  />
                  <TextInput
                    placeholder="Email"
                    value={form.values.reservations[0]?.email}
                    onChange={(e) =>
                      form.setFieldValue(
                        "reservations",
                        editReservation(form.values.reservations, 0, {
                          email: e.target.value,
                        })
                      )
                    }
                  />
                  <div
                    className={`justify-end mt-8 bg-hero-background ${
                      form.values.reservations.length > 1 ? "p-6" : "p-3.5"
                    }  border`}
                  >
                    <div>
                      {form.values.reservations.slice(1).map((res, i) => (
                        <div key={i} className="mb-6">
                          <div className="flex items-center justify-between mb-2.5">
                            <p className="text-[0.95rem]">Roomate {i + 1}</p>
                            <IconX
                              onClick={() => removeReservation(i + 1)}
                              size={18}
                              className="cursor-pointer"
                            />
                          </div>{" "}
                          <TextInput
                            placeholder="Name"
                            autoFocus
                            value={res.name}
                            containerClassName="mb-5"
                            onChange={(e) =>
                              form.setFieldValue(
                                "reservations",
                                editReservation(
                                  form.values.reservations,
                                  i + 1,
                                  {
                                    name: e.target.value,
                                  }
                                )
                              )
                            }
                          />
                          <TextInput
                            placeholder="Email"
                            value={res.email}
                            onChange={(e) =>
                              form.setFieldValue(
                                "reservations",
                                editReservation(
                                  form.values.reservations,
                                  i + 1,
                                  {
                                    email: e.target.value,
                                  }
                                )
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={addReservation}
                        className="flex items-center px-4 py-2 text-sm bg-white shadow font-medium"
                      >
                        Roomate{" "}
                        <IconPlus size={16} stroke={2.5} className="ml-1" />
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={form.handleSubmit}
                    className="px-8 h-[3.2rem] mt-7 w-full flex items-center justify-center bg-primary text-white font-medium"
                  >
                    {isBooking ? <Spinner color="white" size="sm" /> : "Book"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
