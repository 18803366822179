import React from "react";
import { useNavigate } from "react-router-dom";

export default function Room({ room }) {
  const beds = !room.is_available
    ? "Fully booked"
    : room.beds !== room.beds_available
    ? `${room.beds_available} of ${room.beds} rooms left`
    : `${room.beds} beds`;

  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/rooms/${room.id}`, { state: room })}
      className="md:flex w-full bg-white shadow md:h-[15rem] cursor-pointer"
    >
      <img
        src={room.images[0]?.url}
        alt="Bedroom"
        className="h-[12rem] md:h-full w-full md:w-[40%] shrink-0 object-cover"
      />
      <div className="py-7 px-9 md:flex justify-between w-full">
        <div className="text-paragraph flex flex-col">
          <p className="mb-4">Room {room.number}</p>
          <p className="text-xs md:text-sm text-primary mb-5">
            {room.location}
          </p>
          <div className="flex justify-between space-x-5 w-fit mt-auto text-sm font-light text-paragraph ">
            <p className="shrink-0">{beds}</p>
            <div className="bg-gray-300 h-[1rem] w-[0.07rem]" />
            <p className="shrink-0">{room.bathrooms} Bathroom</p>
            {room.study_desk && (
              <>
                <div className="bg-gray-300 h-[1rem] w-[0.07rem]" />
                <p className="shrink-0">Study Desk</p>
              </>
            )}
          </div>
        </div>
        <div className="flex md:flex-col max-md:items-center justify-between max-md:mt-8">
          <p className="font-medium text-primary md:text-[1.1rem]">
            GHS{room.price}
          </p>
          <button
            onClick={() => navigate(`/rooms/${room.id}`, { state: room })}
            className="px-8 py-3 bg-primary text-white text-sm font-medium"
          >
            VIEW
          </button>
        </div>
      </div>
    </div>
  );
}
