import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { IconMenuDeep, IconX } from "@tabler/icons-react";

export const Header = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${
        open ? "h-[295px] md:h-fit" : "h-[80px] md:h-fit"
      } transition-all duration-300 md:flex px-[6%] pt-[4%] md:pt-0 md:px-[8%] items-center justify-between overflow-y-hidden`}
    >
      <div className="flex items-center justify-between">
        <NavLink to="/">
          <img src={Logo} alt="Logo" className="h-[3rem] md:h-[3.5rem]" />
        </NavLink>
        <div className="md:hidden">
          <AnimatePresence mode="wait" initial={false}>
            {open ? (
              <motion.div
                key="close"
                onClick={() => setOpen(false)}
                transition={{ duration: 0.08 }}
                initial={{ opacity: 1, bottom: 15 }}
                animate={{ opacity: 1, bottom: 0 }}
                exit={{ opacity: 0, bottom: 15 }}
                className="relative"
              >
                <IconX size={23} />
              </motion.div>
            ) : (
              <motion.div
                key="open"
                onClick={() => setOpen(true)}
                transition={{ duration: 0.08 }}
                initial={{ opacity: 1, bottom: -15 }}
                animate={{ opacity: 1, bottom: 0 }}
                exit={{ opacity: 0, bottom: -15 }}
                className="relative"
              >
                <IconMenuDeep size={26} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div
        className="md:flex items-center md:space-x-6 space-y-7 md:space-y-0 pt-7 md:pt-0"
        onClick={() => setOpen(false)}
      >
        <Link
          to="/"
          className={`flex justify-center px-2 md:border-b-2 md:pb-7 md:pt-9 ${
            pathname === "/" ? "border-primary" : "border-transparent"
          }`}
        >
          Home
        </Link>
        <Link
          to="/rooms"
          className={`flex justify-center px-2 md:border-b-2 md:pb-7 md:pt-9 ${
            pathname.includes("rooms") ? "border-primary" : "border-transparent"
          }`}
        >
          Book Reservation
        </Link>
        <Link
          to="/gallery"
          className={`flex justify-center px-2 md:border-b-2 md:pb-7 md:pt-9 ${
            pathname.includes("gallery")
              ? "border-primary"
              : "border-transparent"
          }`}
        >
          Gallery
        </Link>
        <Link
          to="/contact"
          className={`flex justify-center px-2 md:border-b-2 md:pb-7 md:pt-9 ${
            pathname.includes("contact")
              ? "border-primary"
              : "border-transparent"
          }`}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};
