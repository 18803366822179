import { useState } from "react";
import { getRooms } from "../services/roomService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export default function useRooms() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [filters, setFilters] = useState(search);

  const { data: rooms, isLoading } = useQuery({
    queryKey: ["rooms", filters],
    queryFn: () => getRooms(filters),
  });

  const searchRooms = () => {
    setFilters(search);
    console.log();
    if (pathname === "/") {
      navigate(`/rooms${search}`);
    }
  };

  const setSearchParams = ({ beds, price__lt, price__gt }) => {
    _setSearchParams({
      beds: beds ?? searchParams.getAll("beds") ?? "",
      price__lt: price__lt ?? searchParams.getAll("price__lt") ?? "",
      price__gt: price__gt ?? searchParams.getAll("price__gt") ?? "",
    });
  };

  const clearSearch = () => {
    _setSearchParams();
    setFilters("");
  };

  return {
    rooms,
    searchRooms,
    searchParams,
    setSearchParams,
    clearSearch,
    isLoading,
  };
}
