import axios from "axios";

export const getRooms = async (filters) => {
  const response = await axios.get(`/rooms/${filters}`);
  return response.data?.data;
};

export const getRoom = async (roomId) => {
  const response = await axios.get(`/rooms/${roomId}`);
  return response.data?.data;
};

export const makeReservation = async (roomId, data) => {
  const response = await axios.post(`/reservation/${roomId}`, data);
  return response.data?.payment_url;
};

export const contact = async (data) => {
  return await axios.post("/contact", data);
};
