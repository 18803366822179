import React from "react";
import Hero from "../components/Hero";
import TextInput from "../components/TextInput";
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconBed,
  IconCurrencyCent,
} from "@tabler/icons-react";
import Partner1 from "../assets/images/partner1.png";
import Partner2 from "../assets/images/partner2.png";
import Partner3 from "../assets/images/partner3.png";
import Partner4 from "../assets/images/partner4.png";
import Shower from "../assets/images/shower.png";
import Bedroom from "../assets/images/bedroom.png";
import Toilet from "../assets/images/toilet.png";
import StudyDesk from "../assets/images/studydesk.png";
import Sink from "../assets/images/sink.png";
import Story from "../assets/images/story.png";
import GalleryImage from "../components/GalleryImage";
import Marquee from "react-fast-marquee";
import ExploreRoom from "../components/ExploreRoom";
import useRooms from "../hooks/useRooms";

export const Home = () => {
  const { searchRooms, searchParams, setSearchParams } = useRooms();

  return (
    <div>
      <Hero />

      <div className="flex justify-center items-center relative bottom-16">
        <div className="md:flex justify-between bg-white p-10 shadow w-fit max-md:space-y-7 md:space-x-14">
          <div className="md:flex max-md:space-y-4 md:space-x-8">
            <TextInput
              value={searchParams.getAll("beds")}
              onChange={(e) => setSearchParams({ beds: e.target.value })}
              placeholder="BEDS"
              left={<IconBed className="text-gray-600 mr-3.5" />}
            />
            <TextInput
              value={searchParams.getAll("price__gt")}
              onChange={(e) => setSearchParams({ price__gt: e.target.value })}
              placeholder="MIN"
              left={<IconCurrencyCent className="text-gray-600 mr-2" />}
            />
            <TextInput
              value={searchParams.getAll("price__lt")}
              onChange={(e) => setSearchParams({ price__lt: e.target.value })}
              placeholder="MAX"
              left={<IconCurrencyCent className="text-gray-600 mr-2" />}
            />
          </div>
          <button
            onClick={searchRooms}
            className="bg-primary text-white px-12 py-3.5 max-md:w-full text-sm"
          >
            SEARCH
          </button>
        </div>
      </div>

      <div className="md:hidden">
        <Marquee gradientWidth={50}>
          <div className="flex justify-center items-center space-x-7 mt-4">
            <img src={Partner1} className="h-[3rem]" alt="Partner" />
            <img src={Partner2} className="h-[3rem]" alt="Partner" />
            <img src={Partner3} className="h-[3rem]" alt="Partner" />
            <img src={Partner4} className="h-[3rem]" alt="Partner" />
          </div>
        </Marquee>
      </div>

      <div className="flex justify-center items-center space-x-7 mt-10 max-md:hidden">
        <img src={Partner1} className="h-[3rem]" alt="Partner" />
        <img src={Partner2} className="h-[3rem]" alt="Partner" />
        <img src={Partner3} className="h-[3rem]" alt="Partner" />
        <img src={Partner4} className="h-[3rem]" alt="Partner" />
      </div>

      <div className="mt-20 md:mt-28 flex flex-col items-center mx-[7%] md:mx-[14%]">
        <h2 className="text-center font-header text-3xl mb-3">
          Discover Our Charm
        </h2>
        <p className="text-center leading-relaxed text-lg opacity-90 mb-8">
          A Visual Journey Through Your Next <br /> Home Away From Home.
        </p>

        <div className="mt-8 max-md:space-y-5 md:space-y-6">
          <div className="md:flex max-md:space-y-5 md:space-x-6">
            <GalleryImage src={Shower} name="Shower" />
            <GalleryImage src={Bedroom} name="Bedroom" />
          </div>
          <div className="md:flex max-md:space-y-5 md:space-x-6">
            <GalleryImage src={Toilet} name="Toilet" />
            <GalleryImage src={StudyDesk} name="Study Desk" />
            <GalleryImage src={Sink} name="Sink" />
          </div>
        </div>

        <button className="border-[1.5px] text-sm px-4 py-2.5 border-black mt-12 font-medium">
          VEIW MORE
        </button>
      </div>

      <div className="mt-28 flex flex-col items-center bg-hero-background py-16 md:py-20 px-[7%]">
        <h2 className="text-center font-header text-3xl mb-3">
          Explore Our Best Rooms
        </h2>
        <p className="text-center leading-relaxed text-lg opacity-90 mb-8">
          A Visual Journey Through Your Next <br /> Home Away From Home.
        </p>

        <div className="md:flex justify-center max-md:space-y-10 md:space-x-12 mt-8">
          <ExploreRoom />
          <ExploreRoom />
          <ExploreRoom />
        </div>

        <button className="border-[1.5px] text-sm px-4 py-2.5 border-black mt-12 font-medium">
          VEIW MORE
        </button>
      </div>

      <div className="mt-20 md:mt-24 mb-20 md:mb-28 mx-[7%] md:mx-[9%]">
        <h2 className="text-center font-header text-3xl mb-3">Our Story</h2>

        <div className="mt-10 md:mt-16 md:flex max-md:space-y-7 md:space-x-14">
          <div className="w-full md:w-[30rem] max-md:h-[17rem] shrink-0 relative">
            <img src={Story} alt="Our Story" className="w-full h-full" />
            <div className="absolute bottom-0 right-0 flex items-center space-x-5 mr-10 mb-6 text-white/80">
              <button className="border border-white px-2">
                <IconArrowNarrowLeft stroke={1.5} />
              </button>
              <p>1/4</p>
              <button className="border border-white px-2">
                <IconArrowNarrowRight stroke={1.5} />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <p className="leading-loose mb-10">
              We are dedicated to eco-friendly housing solutions. Partnering
              with Constructro Innovations LTD (CPI), we prioritize swift and
              sustainable construction. Our buildings use photovoltaic cells for
              energy, efficient LED lighting, and greywater recycling. Local
              manufacturing through ModPro Systems ensures affordability and
              boosts Ghana's economy. Phase 1 of SAMA, funded by Ghana
              Infrastructure Investment Fund (GIF), is a 5000-bed development
              with top-notch amenities. 856 beds will be ready for Semester I,
              2021, and are fully sold out.
            </p>
            <button className="bg-primary text-white px-8 py-3.5 max-md:w-full text-sm w-fit">
              LEARN MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
