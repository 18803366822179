export const editReservation = (array, position, item) => {
  return array.reduce((acc, r, i) => {
    if (position === i) {
      return [...acc, { ...r, ...item }];
    }
    return [...acc, r];
  }, []);
};

export const getPosition = (x, y) => {
  const top = {
    0: "86%",
    1: "72%",
    2: "51%",
    3: "37%",
    4: "14.5%",
    5: "0.3%",
  };

  const left = {
    0: "0.25%",
    1: "11.8%",
    2: "23.4%",
    3: "35%",
    4: "52.8%",
    5: "64.5%",
    6: "76.1%",
    7: "87.8%",
  };

  return { left: left[x], top: top[y] };
};

export const getRoomPosition = (roomNumber) => {
  const x = {
    149: [0, 0],
    150: [0, 1],
    133: [0, 2],
    134: [0, 3],
    117: [0, 4],
    118: [0, 5],

    151: [1, 0],
    152: [1, 1],
    135: [1, 2],
    136: [1, 3],
    119: [1, 4],
    120: [1, 5],

    153: [2, 0],
    154: [2, 1],
    137: [2, 2],
    138: [2, 3],
    121: [2, 4],
    122: [2, 5],

    155: [3, 0],
    156: [3, 1],
    139: [3, 2],
    140: [3, 3],
    123: [3, 4],
    124: [3, 5],

    157: [4, 0],
    158: [4, 1],
    141: [4, 2],
    142: [4, 3],
    125: [4, 4],
    126: [4, 5],

    159: [5, 0],
    160: [5, 1],
    143: [5, 2],
    144: [5, 3],
    127: [5, 4],
    128: [5, 5],

    161: [5, 0],
    162: [5, 1],
    145: [5, 2],
    146: [5, 3],
    129: [5, 4],
    130: [5, 5],

    163: [5, 0],
    164: [5, 1],
    147: [5, 2],
    148: [5, 3],
    131: [5, 4],
    132: [5, 5],
  };
  return getPosition(...x[roomNumber]);
};
