import React from "react";
import Logo from "../assets/images/logo-white.png";
import { Link } from "react-router-dom";
import {
  IconBrandInstagram,
  IconBrandMeta,
  IconBrandTwitter,
} from "@tabler/icons-react";

export const Footer = () => {
  return (
    <div className="bg-black px-[6%] md:px-[8%] text-footer-text font-light pt-9">
      <div className="md:flex justify-between">
        <div className="space-y-3">
          <img src={Logo} alt="Logo" className="h-[3.2rem] md:h-[3.7rem]" />
          <p>contact@samaholdings.com</p>
          <p className="text-[0.9rem]">+233 (59) 638 293</p>
        </div>
        <div className="space-y-2.5 flex flex-col md:items-end text-[0.93rem] my-8 md:my-0">
          <Link to="/book" className="block">
            Reservation
          </Link>
          <Link to="/contact" className="block">
            Contact Us
          </Link>
          <Link to="/gallery" className="block">
            Gallery
          </Link>
        </div>
      </div>

      <div className="h-[1px] w-full bg-footer-text/30 my-7"></div>
      <div className="flex items-center justify-between pb-7 text-sm">
        <p>© 2023 All rights reserved</p>

        <div className="flex space-x-3">
          <Link className="text-white bg-white/[0.12] w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center">
            <IconBrandInstagram stroke={1.3} size={20} />
          </Link>
          <Link className="text-white bg-white/[0.12] w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center">
            <IconBrandTwitter stroke={1.3} size={20} />
          </Link>
          <Link className="text-white bg-white/[0.12] w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center">
            <IconBrandMeta stroke={1.3} size={20} />
          </Link>
        </div>
      </div>
    </div>
  );
};
