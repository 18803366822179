import {
  IconBrandInstagram,
  IconBrandMeta,
  IconBrandTwitter,
} from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import TextInput from "../components/TextInput";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { contact } from "../services/roomService";
import toast from "react-hot-toast";
import { Spinner } from "@nextui-org/react";

export const Contact = () => {
  const { isPending, mutateAsync } = useMutation({ mutationFn: contact });

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    onSubmit: async (values) => {
      try {
        await mutateAsync(values);
        toast.success("Message Sent");
        form.resetForm();
      } catch (err) {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <div className="md:flex justify-between px-[6%] md:px-[8%] pt-14 md:pt-16 pb-24 md:pb-20">
      <div className="max-md:mb-10">
        <h1 className="font-header text-3xl md:text-4xl mb-3">Get In Touch</h1>
        <p className="leading-relaxed text-black/70 md:text-lg">
          Lorem ipsum dolor sit amet consectetur
          <br className="max-md:hidden" /> adipisicing elit. Maxime mollitia,
          molestiae
          <br className="max-md:hidden" /> quas vel sint commodi.
        </p>
        <div className="flex space-x-5 mt-7">
          <Link className="text-black bg-black/[0.12] w-[2rem] h-[2rem] rounded-full flex items-center justify-center">
            <IconBrandInstagram stroke={1.5} size={18} />
          </Link>
          <Link className="text-black bg-black/[0.12] w-[2rem] h-[2rem] rounded-full flex items-center justify-center">
            <IconBrandTwitter stroke={1.5} size={18} />
          </Link>
          <Link className="text-black bg-black/[0.12] w-[2rem] h-[2rem] rounded-full flex items-center justify-center">
            <IconBrandMeta stroke={1.5} size={18} />
          </Link>
        </div>
      </div>
      <div className="space-y-8 md:w-[30rem]">
        <TextInput
          placeholder="NAME"
          autoFocus
          className="py-4"
          value={form.values.name}
          onChange={form.handleChange("name")}
        />
        <TextInput
          placeholder="EMAIL"
          className="py-4"
          value={form.values.email}
          onChange={form.handleChange("email")}
        />
        <TextInput
          placeholder="MESSAGE"
          textarea
          className="py-4"
          rows={5}
          value={form.values.message}
          onChange={form.handleChange("message")}
        />
        <button
          onClick={form.handleSubmit}
          className="bg-primary w-full h-[60px] font-medium text-sm text-white tracking-wide"
        >
          {isPending ? <Spinner color="white" size="sm" /> : "SEND"}
        </button>
      </div>
    </div>
  );
};
