import React from "react";

export default function GalleryImage({ src, name, className }) {
  return (
    <div className={`relative h-[11rem] md:h-[22rem] ${className}`}>
      <img src={src} alt={name} className="h-full w-full" />
      <p className="absolute left-0 top-0 ml-4 mt-4 bg-[#000000] uppercase text-white px-3 py-1.5 tracking-wide bg-opacity-70 text-[0.8rem]">
        {name}
      </p>
    </div>
  );
}
