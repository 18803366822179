import React from "react";
import Info from "../home/components/Info";
import HeroImage from "../assets/images/hero.png";
import CctvImage from "../assets/images/cctv.svg";
import SolarImage from "../assets/images/solar.svg";
import TrowelImage from "../assets/images/trowel.svg";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="bg-hero-background px-[10%] pt-8 pb-28 md:pb-36 md:pt-10 md:flex justify-between items-center">
      <div>
        <h1 className="font-header text-[26px] md:text-[36px]">
          Sustainable <br /> Affordable Modular <br /> Accommodation
        </h1>
        <p className="md:text-[18px] leading-relaxed mt-3">
          We have set out to build 100,000 bed{" "}
          <br className="hidden md:block" /> hostel facilities across major
          schools in <br className="hidden md:block" /> Ghana.
        </p>
        <Link
          to="/rooms"
          className="bg-primary block w-fit text-white font-medium text-sm px-6 py-3 mt-10"
        >
          BOOK RESERVATION
        </Link>
      </div>
      <div className="relative flex justify-center mt-12">
        <div className="absolute left-[-20px] md:left-[-115px] top-[10px] md:top-[10px] z-0 shadow-lg md:shadow">
          <Info icon={CctvImage} text="24/7 Surveillance" />
        </div>
        <div className="absolute left-[-25px] md:left-[-150px] top-[250px] md:top-[320px] z-20 shadow-lg">
          <Info icon={SolarImage} text="Uninterrupted Power Supply" />
        </div>
        <div className="absolute right-[-25px] md:right-[-90px] top-[135px] md:top-[180px] z-20 shadow-lg">
          <Info icon={TrowelImage} text="Maintenance" />
        </div>
        <img
          src={HeroImage}
          alt="Hostel"
          className="w-[90%] md:w-[350px] h-[300px] md:h-[400px] object-cover rounded-t-full md:z-10"
        />
      </div>
    </div>
  );
}
