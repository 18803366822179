import React from "react";
import Bedroom from "../assets/images/bedroom.png";

export default function ExploreRoom() {
  return (
    <div className="w-full md:w-[17rem] bg-white shadow">
      <img src={Bedroom} alt="Bedroom" className="h-[12rem]" />
      <div className="p-6">
        <p className="text-sm text-primary mb-3">TAKORADI</p>
        <p className="text-paragraph mb-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit
        </p>
        <div className="flex justify-between font-medium text-sm">
          <p>2 Beds</p>
          <div className="bg-gray-300 h-[1rem] w-[0.09rem]" />
          <p>1 Bathroom</p>
          <div className="bg-gray-300 h-[1rem] w-[0.09rem]" />
          <p>Study Desk</p>
        </div>
      </div>
    </div>
  );
}
