import React, { useState } from "react";

function TextInput({
  left,
  right,
  className,
  containerClassName,
  error,
  label,
  sublabel,
  optional = false,
  textarea = false,
  noInput = false,
  placeholder,
  value,
  labelClassName,
  ...rest
}) {
  const [focus, setFocus] = useState(false);

  return (
    <div className={`h-fit ${containerClassName}`}>
      {label && (
        <div className={`mb-2 text-[0.92rem] ${labelClassName}`}>
          {label}{" "}
          {optional && (
            <span className="text-[0.92rem] text-gray-400">(optional)</span>
          )}
        </div>
      )}
      {sublabel && (
        <div className="mb-3 text-sm font-light text-gray-500">{sublabel}</div>
      )}
      {textarea ? (
        <textarea
          className={`w-full p-4 outline-0 placeholder:text-xs placeholder:font-extralight border border-gray-300/80 placeholder:tracking-wide focus:border-[1.5px] focus:border-primary ${className}`}
          placeholder={placeholder}
          value={value}
          {...rest}
        />
      ) : (
        <div
          className={`flex bg-white ${
            focus && !error && "border-[1.5px] border-primary"
          } items-center border border-gray-300/80 px-4 ${
            error && "border border-red-500/70"
          }`}
        >
          {left}
          {noInput ? (
            <p
              className={`w-full py-3 text-[0.92rem] ${
                value ? "" : "text-[#9BA3AF] font-extralight"
              }`}
            >
              {value || placeholder}
            </p>
          ) : (
            <input
              className={`w-full bg-transparent py-3 outline-0 placeholder:text-xs placeholder:tracking-wide placeholder:font-extralight ${className}`}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              placeholder={placeholder}
              value={value}
              {...rest}
            />
          )}
          {right}
        </div>
      )}
      {error && (
        <p className="ml-3 mt-1 text-sm font-light text-red-500">{error}</p>
      )}
    </div>
  );
}

export default TextInput;
