import React from "react";

const Info = ({ text, icon }) => {
  return (
    <div className="flex items-center bg-white px-3.5 md:px-5 py-2.5 md:py-3 text-[0.9rem]">
      <img src={icon} className="h-[1.3rem] md:h-[1.7rem] mr-3" alt={text} />
      <span className="text-[0.8rem] md:text-sm">{text}</span>
    </div>
  );
};

export default Info;
