import { IconBed, IconCurrencyCent, IconX } from "@tabler/icons-react";
import React from "react";
import TextInput from "../components/TextInput";
import Room from "../components/Room";
import useRooms from "../hooks/useRooms";
import { Spinner } from "@nextui-org/react";

export const Rooms = () => {
  const {
    rooms,
    searchRooms,
    searchParams,
    setSearchParams,
    clearSearch,
    isLoading,
  } = useRooms();

  return (
    <div className="px-[6%] md:px-[8%] pt-12 md:pt-16 pb-20 md:pb-24 bg-hero-background">
      <div className="flex items-center justify-between mb-9 md:mb-16">
        <h1 className="text-3xl md:text-4xl font-header">Explore Our Rooms</h1>
        <button onClick={clearSearch} className="flex items-center text-sm">
          CLEAR <IconX size={19} className="ml-1.5" />
        </button>
      </div>
      <div className="md:flex justify-between max-md:space-y-7 md:space-x-14 mb-16 md:mb-20">
        <div className="md:flex max-md:space-y-4 md:space-x-8">
          <TextInput
            value={searchParams.getAll("beds")}
            onChange={(e) => setSearchParams({ beds: e.target.value })}
            placeholder="BEDS"
            left={<IconBed className="text-gray-600 mr-3.5" />}
          />
          <TextInput
            value={searchParams.getAll("price__gt")}
            onChange={(e) => setSearchParams({ price__gt: e.target.value })}
            placeholder="MIN"
            left={<IconCurrencyCent className="text-gray-600 mr-2" />}
          />
          <TextInput
            value={searchParams.getAll("price__lt")}
            onChange={(e) => setSearchParams({ price__lt: e.target.value })}
            placeholder="MAX"
            left={<IconCurrencyCent className="text-gray-600 mr-2" />}
          />
        </div>
        <button
          onClick={searchRooms}
          className="bg-primary text-white px-12 py-3.5 max-md:w-full text-sm"
        >
          SEARCH
        </button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[13rem]">
          <Spinner size="lg" />
        </div>
      ) : rooms?.length === 0 ? (
        <div className="flex justify-center items-center text-gray-400 h-[13rem] text-lg">
          No rooms available
        </div>
      ) : (
        <div className="space-y-12">
          {rooms?.map((room) => (
            <Room key={room.id} room={room} />
          ))}
        </div>
      )}

      {/* <div className="flex justify-end space-x-3 mt-14">
        <div className="h-[2.5rem] w-[2.5rem] flex items-center justify-center cursor-pointer bg-primary text-white">
          1
        </div>
        <div className="border border-gray-300/70 h-[2.5rem] w-[2.5rem] flex items-center justify-center cursor-pointer">
          2
        </div>
        <div className="border border-gray-300/70 h-[2.5rem] w-[2.5rem] flex items-center justify-center cursor-pointer">
          3
        </div>
        <div className="flex items-center border border-gray-300/70 h-[2.5rem] px-4 cursor-pointer">
          Next <IconArrowRight className="ml-2" size={20} stroke={1.5} />
        </div>
      </div> */}
    </div>
  );
};
